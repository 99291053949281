import React, { useEffect, useState } from 'react'
import PermissionCreate from '../component/permission/PermissionCreate'
import PermissionList from '../component/permission/PermissionList'
import { Col, Row } from 'antd'
import axios from 'axios';

export default function Permission() {
  const [dataSource, setDataSource] = useState();
  const [tablePagination, setTablePagination] = useState({
    pageSize: 20,
    total: 0,
    onChange: async (page) => {
      setTablePagination(current => ({
        ...current,
        current: page
      }))
    },
    current: 1,
    position: ['bottomLeft'],
  })

  const getAdminList = async (page) => {
    try {
      const { data } = await axios.get('/accounts/search', { 
        params: { 
          page: page-1, 
          size: 20,
          role: "ROLE_ADMIN"
        } 
      });

      const dataOrigin = data.content.map((data) => {
        return { ...data, key: data.id };
      });

      setTablePagination(current => ({
        ...current,
        total: data.totalElements,
        current: page
      }))
      setDataSource(dataOrigin);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    getAdminList(tablePagination.current);
  }, [tablePagination.current]);

  return (
    <>
    <Row style={{ marginBottom: 10 }}>
        <Col span={24}>
            <PermissionCreate
              getAdminList={getAdminList}
            />
        </Col>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <Col span={24}>
            <PermissionList
              dataSource={dataSource}
              setDataSource={setDataSource}
              tablePagination={tablePagination}
            />
        </Col>
      </Row>
    </>
  )
}
