import React from 'react'
import { Button, Table } from 'antd';

import Columns from './Columns';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

export default function PermissionList({ dataSource, setDataSource, tablePagination }) {
  const { t } = useTranslation();

  const onChangeEvt = (e, record, target) => {
    const changeValue = dataSource.map(data => {
        if (data.key === record.key) {
            return { ...data, subRole: target }
        } else {
            return data;
        }
    });
    setDataSource(changeValue);
  }

  const columns = Columns(onChangeEvt).map((col) => {
    return {
      ...col,
      onCell: (record) => ({
        record,
        title: col.title,
      }),
    };
  });

  const onSave = async () => {
    try {
      await Promise.all(
        dataSource.map(data => axios.post(`/accounts/change-sub-admin-role`,
          { targetAccountId: data.id, subRole: data.subRole }
        )));
      alert(t('common.message.status-change-complete'));
    } catch (error) {
      alert(t('common.message.status-change-failed'));
      console.log(error);
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Table
        className='account-table'
        scroll={{ x: true }}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={tablePagination}
      />
      <Button type='primary'
        style={{ marginTop: 10, alignSelf: 'flex-end' }}
        onClick={onSave}
      >
        Save
      </Button>
    </div>
  );
};