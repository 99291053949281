import React, { useState, useEffect } from 'react';
import {Pagination, Table} from 'antd';
import axios from 'axios';
import {dateFormat} from "../../common/Util";

export default function AdminPoint({ detail, getAccountDetail })  {
    const [data, setData] = useState([]);
    const [pointData, setPointData] = useState({});
    const [currentPage, setCurrentPage] = useState(1); // Add a state variable for the current page
    const [totalPages, setTotalPages] = useState(0); // Add a state variable for the total number of pages


    useEffect(() => {
        const fetchData = async () => {
            const result = await axios.get('/point/admin-point', {
                params: {
                    id: detail.id,
                    page: currentPage - 1, // Use the current page as a parameter
                    size: 10,
                    sort: 'createdOn,desc'
                }
            });

            // Map through the data and replace "포인트 사용" with "Point Used" in the description
            const mappedData = result.data.content.map(item => {
                if (item.description === "포인트 사용") {
                    return {...item, description: "Point Used"};
                } else {
                    return item;
                }
            });

            setData(mappedData);
            setTotalPages(result.data.totalPages); // Set the total number of pages

            const pointResult = await axios.get(`/point/${detail.id}`);

            setPointData(pointResult.data);
        };

        fetchData();
    }, [currentPage]);

    const handlePageChange = (page) => {
        setCurrentPage(page); // Update the current page when it changes
    };

    const columns = [

        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },


        {
            title: 'Received Point',
            dataIndex: 'receivedPoint',
            key: 'receivedPoint',
        },
        {
            title: 'Spent Point',
            dataIndex: 'spentPoint',
            key: 'spentPoint',
        },
        {
            title: 'Created On',
            dataIndex: 'createdOn',
            key: 'createdOn',
            render: (val) => <span>{dateFormat(val)}</span>
        },


    ];

    return (
        <div>
            <div>
                <p>Total Own Point: {pointData.totalOwnPoint}</p>
                <p>Total Earn Point: {pointData.totalEarnPoint}</p>
                <p>Total Used Point: {pointData.totalUsedPoint}</p>
            </div>
            <Table columns={columns} dataSource={data} pagination={false} />
            <Pagination current={currentPage} total={totalPages * 20} onChange={handlePageChange} />

        </div>
    );
}
