import React from "react";
import { Card, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { addCommas, getLanguageKey } from '../../common/Util';

const { Text } = Typography;

export default function OrderInfo({ detail }) {
    const { t } = useTranslation();
    
    return (
        <Card title={t(getLanguageKey('orders', detail.status))}> 
            <Card.Grid hoverable={false} className="grid-style"> 
                <Text type="secondary">{ t('orders.number') }</Text> 
                <p>{ detail.id }</p> 
            </Card.Grid> 
            <Card.Grid hoverable={false} className="grid-style"> 
                <Text type="secondary">{ t('accounts.number') }</Text> 
                <p>{ detail.account.id }</p> 
            </Card.Grid> 
            <Card.Grid hoverable={false} className="grid-style"> 
                <Text type="secondary">{ t('common.nickname') }</Text> 
                <p>{ detail.account.nickName }</p> 
            </Card.Grid> 
            <Card.Grid hoverable={false} className="grid-style"> 
                <Text type="secondary">{ t('orders.boxes-paid') }</Text> 
                <p>{ detail.paidBoxNumber }</p> 
            </Card.Grid> 
            <Card.Grid hoverable={false} className="grid-style"> 
                <Text type="secondary">{ t('orders.an-open-box') }</Text> 
                <p>{ detail.openBoxNumber }</p> 
            </Card.Grid> 
            <Card.Grid hoverable={false} className="grid-style"> 
                <Text type="secondary">{ t('orders.refunded-box') }</Text> 
                <p>{ detail.cancelBoxNumber }</p> 
            </Card.Grid> 
            <Card.Grid hoverable={false} className="grid-style"> 
                <Text type="secondary">{ t('orders.premium-box') }</Text> 
                <p>{ detail.premiumBoxNumber }</p> 
            </Card.Grid> 
            <Card.Grid hoverable={false} className="grid-style"> 
                <Text type="secondary">{ t('orders.normal-box') }</Text> 
                <p>{ detail.normalBoxNumber }</p> 
            </Card.Grid> 
            <Card.Grid hoverable={false} className="grid-style"> 
                <Text type="secondary">{ t('orders.base-box') }</Text> 
                <p>{ detail.baseBoxNumber }</p> 
            </Card.Grid>
            <Card.Grid hoverable={false} className="grid-style"> 
                <Text type="secondary">{ t('orders.premium-price') }</Text> 
                <p>{ addCommas(detail.premiumBoxPrice) }</p> 
            </Card.Grid> 
            <Card.Grid hoverable={false} className="grid-style"> 
                <Text type="secondary">{ t('orders.normal-price') }</Text> 
                <p>{ addCommas(detail.normalBoxPrice) }</p> 
            </Card.Grid> 
            <Card.Grid hoverable={false} className="grid-style"> 
                <Text type="secondary">{ t('orders.base-price') }</Text> 
                <p>{ addCommas(detail.baseBoxPrice) }</p> 
            </Card.Grid> 
        </Card>
    )
}