import React, { useState, useEffect } from 'react';
import { Input, Button, message, Row, Col } from 'antd';

import AccountList from '../component/account/list/AccountList';

import axios from 'axios';
import { useTranslation } from 'react-i18next';

export default function Account() {
    const [dataSource, setDataSource] = useState();
    const [searchKeyword, setSearchKeyword] = useState(null); // Add this line
    const [couponCode, setCouponCode] = useState(''); // Add this line
    const [selectedAccounts, setSelectedAccounts] = useState([]); // Add this line
    const [messageText, setMessageText] = useState(''); // Add this line
    const [tablePagination, setTablePagination] = useState({
        pageSize: 20,
        total: 0,
        current: 1,
        position: ['bottomLeft'],
        pageSizeOptions: ['10', '20', '30', '40'],
        showSizeChanger: true
    })
    const [prevSorter, setPrevSorter] = useState({});

    const { t } = useTranslation();

    const getAccountList = async (page, size, keword, sort) => { // Add 'nickname' parameter
        try {
            const { data } = await axios.get('/accounts/search/any', { // Change the endpoint to '/accounts/search'
                params: {
                    page: page-1,
                    size,
                    sort: sort ? sort : 'id,desc',
                    query: keword? keword : null // Add 'keword' parameter
                }
            });

            const dataOrigin = data.content.map((data) => {
                return { ...data, key: data.id };
            });

            setTablePagination(current => ({
                ...current,
                total: data.totalElements,
                current: page
            }))
            setDataSource(dataOrigin);
        } catch (error) {
            console.error(error.message);
        }
    };

    useEffect(() => {
        getAccountList(tablePagination.current, tablePagination.pageSize, searchKeyword); // Add 'searchKeyword' argument
    }, [searchKeyword]); // Add 'searchKeyword' to the dependency array

    const distributeCoupon = async (accountIds, couponCode) => {
        if (!accountIds.length) {
            message.error('No accounts selected.');
            return;
        }

        if (!couponCode) {
            message.error('No coupon code entered.');
            return;
        }

        try {
            const { data } = await axios.post('/admin/distribute-coupon', { accountIds, couponCode });
            console.log(data); // Log the response for debugging
            message.success('Coupons distributed successfully.'); // Show success message
        } catch (error) {
            console.error(error.message);
            const errorMessage = error.response?.data || 'Failed to distribute coupons.';
            message.error(errorMessage); // Show error message
        }
    };

    const sendText = async (accountIds, messageText) => {
        if (!accountIds.length) {
            message.error('No accounts selected.');
            return;
        }

        if (!messageText) {
            message.error('No text entered.');
            return;
        }

        try {
            const { data } = await axios.post('/admin/send-text', { accountIds, text: messageText });
            console.log(data); // Log the response for debugging
            message.success('Text sent successfully.'); // Show success message
        } catch (error) {
            console.error(error.message);
            const errorMessage = error.response?.data || 'Failed to send text.';
            message.error(errorMessage); // Show error message
        }
    };

    const handleTableChange = async (pagination, filters, sorter) => {
        let field = sorter.field;
        setTablePagination(current => ({
          ...current,
          current: pagination.current,
          pageSize: pagination.pageSize
        }))

        // 정렬 기준이 변경되었는지 확인
        const isSorterChanged = prevSorter.field !== sorter.field || prevSorter.order !== sorter.order;
    
        // 정렬 기준이 변경되었다면 페이지네이션을 첫 페이지로 설정, 그렇지 않으면 현재 페이지 사용
        const currentPage = isSorterChanged ? 1 : pagination.current;

        await getAccountList(currentPage, pagination.pageSize, searchKeyword,
            `${field ? field : 'id'},${sorter.order === 'ascend' ? 'asc' : 'desc'}`);
    
        // 현재 정렬 기준을 이전 정렬 기준으로 저장
        setPrevSorter(sorter);
    };

    return (
        <>
            <Row gutter={16}>
                <Col span={8}>
                    <Input.Search
                        placeholder={t('Search nickName')}
                        onSearch={value => setSearchKeyword(value)}
                        style={{ width: '100%', marginBottom: '1rem' }}
                    />
                </Col>
                <Col span={8}>
                    <Input
                        placeholder={t('Coupon Code')}
                        value={couponCode}
                        onChange={e => setCouponCode(e.target.value)}
                        style={{ width: '100%', marginBottom: '1rem' }}
                    />
                </Col>
                <Col span={8}>
                    <Button
                        onClick={() => distributeCoupon(selectedAccounts, couponCode)}
                        style={{ width: '100%', marginBottom: '1rem' }}
                    >
                        {t('Distribute Coupon')}
                    </Button>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Input.TextArea
                        placeholder={t('Enter text')}
                        value={messageText}
                        onChange={e => setMessageText(e.target.value)}
                        style={{ width: '100%', marginBottom: '1rem' }}
                        autoSize={{ minRows: 4, maxRows: 4 }}
                    />
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Button
                        onClick={() => sendText(selectedAccounts, messageText)}
                        style={{ width: '100%', marginBottom: '1rem' }}
                    >
                        {t('Send Text')}
                    </Button>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <AccountList
                        dataSource={dataSource}
                        selectedAccounts={selectedAccounts} // Pass selectedAccounts to AccountList
                        setSelectedAccounts={setSelectedAccounts} // Pass setSelectedAccounts to AccountList
                        tablePagination={tablePagination}
                        handleTableChange={handleTableChange}
                    />
                </Col>
            </Row>
        </>
    )
}