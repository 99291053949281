import React from 'react';
import { addCommas, roundToFive } from '../../common/Util';
import { useTranslation } from 'react-i18next';

export default function Columns (props) {
  const { t } = useTranslation();

  const columns = [ 
    { 
      title: t('products.category'), 
      dataIndex: 'groupName', 
    },
    { 
      title: `${ t('products.target-quantity') } (${props && props.targetQtySum})`, 
      dataIndex: 'targetQtySum',
    },
    { 
      title: t('products.probability'), 
      dataIndex: 'targetQtyProbability', 
      render: (val) => <span>{ roundToFive(val) }</span>,
    },
    { 
      title: `${ t('products.products-count') } (${props && props.totalProducts})`, 
      dataIndex: 'totalProducts', 
    },
    { 
      title: t('products.cost-average'), 
      dataIndex: 'avgPurchasePrice', 
      render: (val) => <span>{ addCommas(val) }</span>,
    },
    { 
      title: t('products.retail-average'), 
      dataIndex: 'avgRetailPrice',
      render: (val) => <span>{ addCommas(val) }</span>,
    },
    { 
      title: t('products.total-cost'), 
      dataIndex: 'totalPurchasePrice',
      render: (val) => <span>{ addCommas(val) }</span>,
    },
    { 
      title: t('products.total-retail'), 
      dataIndex: 'totalRetailPrice',
      render: (val) => <span>{ addCommas(val) }</span>,
    }
  ]; 
 
  return columns; 
};
