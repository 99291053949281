import React, { useState } from "react";
import { Card, Typography, List, Input, Checkbox, Button, Form, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const { Text } = Typography;

export default function AccountInfo({ detail, getAccountDetail }) {
    const { t } = useTranslation();

    // Initialize state for each field
    const [nickName, setNickName] = useState(detail.nickName);
    const [email, setEmail] = useState(detail.email);
    const [password, setPassword] = useState("");
    const [phoneNumber, setPhoneNumber] = useState(detail.phoneNumber);
    const [name, setName] = useState(detail.name);
    const [isActive, setIsActive] = useState(detail.isActive);
    const [point, setPoint] = useState(detail.point);
    const [isFakeUser, setIsFakeUser] = useState(detail.isFakeUser);
    const [isBlackUser, setIsBlackUser] = useState(detail.isBlackUser);
    const [myCode, setMyCode] = useState(detail.myCode);
    const [referralCode, setReferralCode] = useState(detail.referralCode);
    const [profileImage, setProfileImage] = useState(detail.profileImage);

    // Function to handle save button click
    const handleSave = async () => {
        try {
            await axios.put(`/admin/accounts/${detail.id}`, {
                nickName,
                email,
                phoneNumber,
                name,
                isActive,
                point,
                isFakeUser,
                isBlackUser,
                myCode,
                referralCode,
                profileImage
            });
            alert('User info updated successfully');
        } catch (error) {
            console.error(error);
            alert('Failed to update user info');
        }
    };

    const handleDelete = async () => {
        try {
            if (window.confirm("Are you sure to delete this user?")) {
                await axios.put(`/admin/accounts/${detail.id}`, {
                    nickName: "****",
                    email,
                    phoneNumber,
                    name: "****",
                    isActive: false,
                    point,
                    isFakeUser,
                    isBlackUser,
                    myCode,
                    referralCode,
                    profileImage
                });
                await getAccountDetail(detail.id);
                alert('User deleted successfully');
            }
        } catch (error) {
            console.error(error);
            alert('Failed to update user info');
        }
    };

    const handleResetPassword = async () => {
        try {
            await axios.patch(`/accounts/reset-password`, {
                userId: detail.id,
                newPassword: password
            });
            alert('User password updated successfully');
        } catch (error) {
            console.error(error);
            alert('Failed to update user password');
        }
    };

    return (
        <Card title={
            <>
                <span style={{ fontSize: '20px', marginRight: '10px' }}>{nickName}</span>

            </>
        }>
            <Form layout="vertical">
                <Form.Item label="ID">
                    <Input value={detail.id} disabled />
                </Form.Item>
                <Form.Item label={t('accounts.nickname')}>
                    <Input value={nickName} onChange={e => setNickName(e.target.value)} />
                </Form.Item>
                <Form.Item label={t('accounts.email')}>
                    <Input value={email} onChange={e => setEmail(e.target.value)} />
                </Form.Item>
                <Row align="middle" justify="space-between">
                    <Col xs={20}>
                        <Form.Item label={t('accounts.password')}>
                            <Input value={password} onChange={e => setPassword(e.target.value)} />
                        </Form.Item>
                    </Col>
                    <Col xs={4}>
                        <Button
                            style={{ width: "100%", margin: "10px" }}
                            onClick={handleResetPassword}
                        >
                            Save
                        </Button>
                    </Col>
                </Row>
                <Form.Item label={t('accounts.phone-number')}>
                    <Input value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
                </Form.Item>
                <Form.Item label={t('common.username')}>
                    <Input value={name} onChange={e => setName(e.target.value)} />
                </Form.Item>
                <Form.Item label={t('accounts.point')}>
                    <Input value={point} onChange={e => setPoint(e.target.value)} />
                </Form.Item>
                <Form.Item label={t('accounts.my-code')}>
                    <Input value={myCode} onChange={e => setMyCode(e.target.value)} />
                </Form.Item>
                <Form.Item label={t('accounts.referral-code')}>
                    <Input value={referralCode} onChange={e => setReferralCode(e.target.value)} />
                </Form.Item>
                <Form.Item label={t('accounts.is-active')}>
                    <Checkbox checked={isActive} onChange={e => setIsActive(e.target.checked)} />
                </Form.Item>
                <Form.Item label={t('accounts.fake-user')}>
                    <Checkbox checked={isFakeUser} onChange={e => setIsFakeUser(e.target.checked)} />
                </Form.Item>
                <Form.Item label={t('accounts.black-user')}>
                    <Checkbox checked={isBlackUser} onChange={e => setIsBlackUser(e.target.checked)} />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" onClick={handleSave}>Save</Button>
                    <Button type="primary" onClick={handleDelete}>Delete</Button>
                </Form.Item>
            </Form>
        </Card>
    )
}
