import React, { useReducer, useEffect } from 'react';
import axios from 'axios';
import { Form, Input, Button, Table, message, Space } from 'antd';

const initialState = {
    faqs: [],
};

function reducer(state, action) {
    switch (action.type) {
        case 'SET_FAQS':
            return { ...state, faqs: action.payload };
        default:
            throw new Error();
    }
}

export default function FAQs() {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        axios.get('/faqs')
            .then(response => {
                dispatch({ type: 'SET_FAQS', payload: response.data });
                message.success('Data loaded successfully');
            })
            .catch(error => {
                message.error('Error loading data');
            });
    }, []);

    const handleAdd = async () => {
        const newFAQ = { title: '', content: '' };
        try {
            const response = await axios.post('/faqs', newFAQ);
            dispatch({ type: 'SET_FAQS', payload: [...state.faqs, response.data] });
        } catch (error) {
            console.error('Error creating FAQ:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`/faqs/${id}`);
            dispatch({ type: 'SET_FAQS', payload: state.faqs.filter(faq => faq.id !== id) });
            message.success('FAQ deleted successfully');
        } catch (error) {
            console.error(`Error deleting FAQ with ID ${id}:`, error);
            message.error(`Error deleting FAQ with ID ${id}`);
        }
    };

    const handleSaveAll = async () => {
        for (const faq of state.faqs) {
            try {
                await axios.put(`/faqs/${faq.id}`, faq);
            } catch (error) {
                console.error(`Error saving FAQ with ID ${faq.id}:`, error);
                message.error(`Error saving FAQ with ID ${faq.id}`);
                return;
            }
        }
        message.success('All changes saved successfully');
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            render: (_, record) => (
                <Input
                    value={record.title}
                    onChange={event => {
                        const newTitle = event.target.value;
                        dispatch({ type: 'SET_FAQS', payload: state.faqs.map(faq => faq.id === record.id ? { ...faq, title: newTitle } : faq) });
                    }}
                />
            ),
        },
        {
            title: 'Content',
            dataIndex: 'content',
            render: (_, record) => (
                <Input.TextArea
                    value={record.content}
                    onChange={event => {
                        const newContent = event.target.value;
                        dispatch({ type: 'SET_FAQS', payload: state.faqs.map(faq => faq.id === record.id ? { ...faq, content: newContent } : faq) });
                    }}
                />
            ),
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            render: (_, record) => (
                <Button onClick={() => handleDelete(record.id)}>Delete</Button>
            ),
        },
    ];

    return (
        <div>
            <Space style={{marginBottom: 16}}>
                <Button onClick={handleAdd}>Add</Button>
                <Button onClick={handleSaveAll}>Save All</Button>
            </Space>
            <Table dataSource={state.faqs} rowKey="id" columns={columns}/>
        </div>
    );
}
