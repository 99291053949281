import React, { useState } from 'react';
import { numberValidator } from '../../component/common/Util';

import { Button, Form, Input, Checkbox, Row, Col, Typography } from 'antd';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const { Title } = Typography;

export default function BoxAdd() {
    const [isConvert, setIsConvert] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleFormSubmit = async (values) => {
        await axios.post(`/box-items`, {
            ...values,
            isConvert,
            isActive
        })
        .then(() => {
            navigate('/box-settings');
        });
    };

    const handleCheckbox = (type) => (e) => {
        if (type === 'convert') {
            setIsConvert(e.target.checked);
        } else {
            setIsActive(e.target.checked);
        }
    }

    return (
        <>
            <Title level={2}>{ t("settings.box.add-box") }</Title>
            <Form
                name="customized_form_controls"
                layout="inline"
                onFinish={handleFormSubmit}
            >
                <Row style={{ marginBottom: 10, width: '100%' }}>
                    <Form.Item
                        name="boxCode"
                        rules={[
                        {
                            required: true,
                            message: 'Please input your Box Code!',
                        },
                        ]}
                        style={{ marginBottom: 10, width: '100%' }}
                    >
                        <Input addonBefore={t('settings.box.code')} type="text" />
                    </Form.Item>
                    <Form.Item
                        name="boxRealName"
                        rules={[
                        {
                            required: true,
                            message: 'Please input your Box Name!',
                        },
                        ]}
                        style={{ marginBottom: 10, width: '100%' }}
                    >
                        <Input addonBefore={t('settings.box.real-name')} type="text" />
                    </Form.Item>
                    <Form.Item
                        name="boxPrice"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Box Price!',
                            },
                            numberValidator('Box Price'),
                        ]}
                        style={{ marginBottom: 10, width: '100%' }}
                    >
                        <Input addonBefore={t('settings.box.price')} type="text" />
                    </Form.Item>
                    <Form.Item
                        name="boxImageURL"
                        rules={[
                        {
                            required: true,
                            message: 'Please input your Icon URL!',
                        },
                        ]}
                        style={{ marginBottom: 10, width: '100%' }}
                    >
                        <Input addonBefore={t('settings.box.icon-url')} type="text" />
                    </Form.Item>
                    <Form.Item
                        name="boxBackgroundImageURL"
                        rules={[
                        {
                            required: true,
                            message: 'Please input your Background Image URL!',
                        },
                        ]}
                        style={{ marginBottom: 10, width: '100%' }}
                    >
                        <Input addonBefore={t('settings.box.background-image-url')} type="text" />
                    </Form.Item>
                    <Form.Item
                        name="changePoint"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Refund Price!',
                            },
                            numberValidator('Refund Price'),
                        ]}
                        style={{ marginBottom: 10, width: '100%' }}
                    >
                        <Input addonBefore={t('settings.box.refund-price')} type="text" />
                    </Form.Item>
                    <Form.Item
                        style={{ marginBottom: 10, width: '100%' }}
                    >
                        <Checkbox checked={isConvert} onChange={handleCheckbox('convert')}>
                            { t('settings.box.convert-a-box') }
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        name="isActive"
                        style={{ marginBottom: 10, width: '100%' }}
                    >
                        <Checkbox checked={isActive} onChange={handleCheckbox('active')}>
                            { t('settings.box.active') }
                        </Checkbox>
                    </Form.Item>
                </Row>
                <Row gutter={3}>
                    <Col>
                        <Form.Item>
                            <Button onClick={() => { navigate(-1) }}>
                            {t('common.back')}
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                            {t('common.submit')}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}