import React from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { dateFormat } from '../common/Util';
import { Checkbox } from 'antd';

export default function Columns (onChangeEvt) {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('accounts.number'),
      dataIndex: 'id',
      render: (val, record) => (
        <>
          <Link to={`/account-detail?id=${val}`}>{ val }</Link>
        </>
      ),
    },
    {
      title: t('common.nickname'),
      dataIndex: 'nickName',
    },
    {
      title: t('accounts.email'),
      dataIndex: 'email',
    },
    {
      title: t('accounts.create-on'),
      dataIndex: 'createdOn',
      render: (val) => <span>{ dateFormat(val) }</span>, 
    },
    {
      title: t('permission.master'),
      dataIndex: 'subRole',
      render: (val, record) => {
        return <Checkbox
          checked={!!(val == 'ROLE_SUB_MASTER')} 
          onChange={(e) => onChangeEvt(e, record, 'ROLE_SUB_MASTER')}
        />
      },  
    },
    {
      title: t('permission.sub-master'),
      dataIndex: 'subRole',
      render: (val, record) => {
        return <Checkbox
          checked={!!(val == 'ROLE_SUB_SUBMASTER')} 
          onChange={(e) => onChangeEvt(e, record, 'ROLE_SUB_SUBMASTER')}
        />
      }, 
    },
    {
      title: t('permission.delivery'),
      dataIndex: 'subRole',
      render: (val, record) => {
        return <Checkbox
        checked={!!(val == 'ROLE_SUB_DELIVERY')} 
          onChange={(e) => onChangeEvt(e, record, 'ROLE_SUB_DELIVERY')}
        />
      },
    },
    {
      title: t('permission.inquiry'),
      dataIndex: 'subRole',
      render: (val, record) => {
        return <Checkbox
        checked={!!(val == 'ROLE_SUB_INQUIRY')} 
          onChange={(e) => onChangeEvt(e, record, 'ROLE_SUB_INQUIRY')}
        />
      },
    },
  ];

  return columns;
};
