import { Table, Checkbox } from 'antd';
import React from 'react';

import Columns from './Columns';

export default function AccountList({ dataSource, selectedAccounts, setSelectedAccounts, tablePagination, handleTableChange }) {
  const columns = [
    {
      title: 'Select',
      dataIndex: 'select',
      render: (_, record) => (
          <Checkbox
              checked={selectedAccounts.includes(record.key)}
              onChange={e => {
                if (e.target.checked) {
                  setSelectedAccounts([...selectedAccounts, record.key]);
                } else {
                  setSelectedAccounts(selectedAccounts.filter(accountId => accountId !== record.key));
                }
              }}
          />
      )
    },
    ...Columns()
  ].map((col) => {
    return {
      ...col,
      onCell: (record) => ({
        record,
        title: col.title,
      }),
    };
  });

  return (
    <div className='table-container'>
      <Table
        className='account-table'
        scroll={{ x: true }}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={tablePagination}
        onChange={handleTableChange}
      />
    </div>
  );
};