import React from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { addCommas, dateFormat, getLanguageKey } from '../../common/Util';

export default function Columns () {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('orders.number'),
      dataIndex: 'id',
      render: (val, record) => (
        <>
          <Link to={`/order-detail?id=${val}`}>{ val }</Link>
        </>
      ),
      sorter: true,
    },
    {
      title: t('common.nickname'),
      dataIndex: 'nickName',
      render: (text, record) => <Link to={`/account-detail?id=${record.account.id}`}>{text}</Link>,
      sorter: true,
    },
    {
      title: t('orders.total-box-price'),
      dataIndex: 'totalAmount',
      render: (val) => <span>{ addCommas(val) }</span>,
    },
    {
      title: t('orders.total-amount-paid'),
      dataIndex: 'totalPaidAmount',
      render: (val) => <span>{ addCommas(val) }</span>,
    },
    {
      title: t('orders.total-usage-points'),
      dataIndex: 'totalUsedPoint',
      render: (val) => <span>{ addCommas(val) }</span>,
    },
    {
      title: t('orders.total-payback-points'),
      dataIndex: 'totalExchangedPoint',
      render: (val) => <span>{ addCommas(val) }</span>,
    },
    {
      title: t('orders.total-refund-amount'),
      dataIndex: 'refundPaidAmount',
      render: (val) => <span>{ addCommas(val) }</span>,
    },
    {
      title: t('orders.total-refund-points'),
      dataIndex: 'refundUsedPoint',
      render: (val) => <span>{ addCommas(val) }</span>,
    },
    {
      title: t('orders.boxes-paid'),
      dataIndex: 'paidBoxNumber',
    },
    {
      title: t('orders.an-open-box'),
      dataIndex: 'openBoxNumber',
    },
    {
      title: t('orders.refunded-box'),
      dataIndex: 'cancelBoxNumber',
    },
    {
      title: t('orders.payback-box'),
      dataIndex: 'exchangePointBoxNumber',
    },
    {
      title: t('orders.premium-box'),
      dataIndex: 'premiumBoxNumber',
    },
    {
      title: t('orders.normal-box'),
      dataIndex: 'normalBoxNumber',
    },
    {
      title: t('orders.base-box'),
      dataIndex: 'baseBoxNumber',
    },
    {
      title: t('orders.premium-price'),
      dataIndex: 'premiumBoxPrice',
      render: (val) => <span>{ addCommas(val) }</span>,
    },
    {
      title: t('orders.normal-price'),
      dataIndex: 'normalBoxPrice',
      render: (val) => <span>{ addCommas(val) }</span>,
    },
    {
      title: t('orders.base-price'),
      dataIndex: 'baseBoxPrice',
      render: (val) => <span>{ addCommas(val) }</span>,
    },
    {
      title: t('orders.create-time'),
      dataIndex: 'createdOn',
      render: (val) => <span>{ dateFormat(val) }</span>,
      sorter: true,
    },
    {
      title: t('common.status'),
      dataIndex: 'status',
      render: (val) => <span>{ t(`${getLanguageKey('orders', val)}`) }</span>,
      sorter: true,
    },
  ];

  return columns;
};
