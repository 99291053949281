import React, {useEffect, useState} from "react";
import { Button, Card, List, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { DatePicker } from 'antd';

import './detail.css';
import { getLanguageKey } from "../../common/Util";

import moment from 'moment';
import axios from 'axios'

const { Option } = Select;

export default function AccountInventory({ detail, statusList, getAccountInventory, user, getAccountDetail, totalElements }) {
    const { t } = useTranslation();
    const [selectedStatuses, setSelectedStatuses] = useState(['BOX_OPENED']);
    const [startDate, setStartDate] = useState(moment().subtract(1, 'months'));
    const [endDate, setEndDate] = useState(moment());


    useEffect(() => {
        if (startDate && endDate) {
            console.log(startDate);
            getAccountInventory(1, user.id,selectedStatuses,startDate.format('YYYY-MM-DDTHH:mm:ss'),endDate.format('YYYY-MM-DDTHH:mm:ss'));
        }

    }, [selectedStatuses, startDate, endDate]);


    const saveStatus = async (item) => {
        try {
            await axios.put(`/admin/order-item-change-status`,
                { id: item.id, status: item.status }
            ).then(() => {
                alert(t('common.message.status-change-complete'));
                getAccountInventory(0, user.id);
            });
        } catch (error) {
            console.log(error.message);
        }
    }

    const forcePayback = async (item) => {
        try {
            await axios.post(`/admin/payback`,
                {
                    orderItemsId: [ item.id ],
                    userEmail: user.email
                }
            ).then(() => {
                alert(t('common.message.status-change-complete'));
                getAccountDetail(user.id);
                getAccountInventory(1, user.id);
            });
        } catch (error) {
            console.log(error.message);
        }
    }
    const statusButtons = statusList
        .filter(status => ['ORDERED', 'BOX_OPENED', 'POINT_REFUNDED', 'DELIVERY'].includes(status.name))
        .map((status) => (
            <Button
                key={status.name}
                type={selectedStatuses.includes(status.name) ? "primary" : "default"}
                onClick={() => {
                    if (selectedStatuses.includes(status.name)) {
                        setSelectedStatuses(selectedStatuses.filter(s => s !== status.name));
                    } else {
                        setSelectedStatuses([...selectedStatuses, status.name]);
                    }
                }}
            >
                {status.name}
            </Button>
        ));

    return (
        <Card title={
            <span style={{fontSize: '20px', marginRight: '10px'}}>
                    Customer Inventory
                </span>
        }>
            <div>
                <DatePicker value={startDate} onChange={date => setStartDate(date)}/>
                <DatePicker value={endDate} onChange={date => setEndDate(date)}/>
            </div>
            {statusButtons}
            <List
                itemLayout="vertical"
                size="large"
                pagination={{
                    total: totalElements,
                    onChange: (page) => {
                        getAccountInventory(page, user.id);
                    },
                    pageSize: 20,
                }}
                dataSource={detail}
                renderItem={(item) => (
                    <List.Item
                        key={item.title}
                        style={{display: 'flex', flexDirection: 'row-reverse'}}
                        extra={
                            <img
                                width={100}
                                alt="logo"
                                src={
                                    item.product !== null ? item.product.mainImageURL
                                        : "https://pocket-pangpang.s3.ap-northeast-2.amazonaws.com/admin/box.png"
                                }
                            />
                        }
                        className="inventory-list-item"
                    >
                        {
                            <Card
                                title={
                                    <>
                                        <>
                                            <div>[{item.id}]</div>
                                        </>
                                        {item.product &&
                                            <>
                                                <div>{item.product.brand}</div>
                                                <small style={{color: 'grey'}}>
                                                    {item.product.productName}
                                                </small>
                                            </>
                                        }
                                    </>
                                }
                                style={{border: 'none', boxShadow: 'none'}}
                                headStyle={{borderBottom: 'none'}}
                            >
                                <>
                                    <div>
                            <span style={{fontSize: '20px', marginRight: '10px'}}>
                                {t(getLanguageKey('orders.detail', item.status))}
                            </span>
                                        {item.product && <>
                                            <p>
                                                {t('products.retail-price')} : {(item.product.retailPrice).toLocaleString()}
                                            </p>
                                            <p>
                                                {t('products.shipping-guage')} : {item.product.shippingGuage}
                                            </p>
                                            <p>
                                                {t('orders.open-date')} : {moment(item.openTime).format("YYYY-MM-DD HH:mm:ss")}
                                            </p>
                                        </>}
                                    </div>
                                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
                                        <Select defaultValue={item.status}
                                                style={{flexGrow: 1, marginRight: 10}}
                                                onChange={(value) => item.status = value}
                                        >
                                            {statusList.map((status) => (
                                                <Option key={status.name} value={status.name}>{status.name}</Option>
                                            ))}
                                        </Select>
                                        <Button type="primary" onClick={() => saveStatus(item)}>
                                            {t('common.save')}
                                        </Button>
                                    </div>
                                    <div>
                                        <Button type="primary" block onClick={() => forcePayback(item)}>
                                            Forced payback
                                        </Button>
                                    </div>
                                </>
                            </Card>
                        }
                    </List.Item>
                )}
            />
        </Card>
    )
}
