import {Checkbox, Modal, Table} from 'antd';
import React, {useEffect, useState} from 'react';
import { Select, Button } from 'antd';
import Columns from './Columns';


import './delivery.css';
import axios from "axios";
import Cookies from "js-cookie";

export default function DeliveryList({ dataSource, tablePagination, handleTableChange,refreshTable,downloadExcel,downloadSelectedExcel }) {

  const [status, setStatus] = useState('');
  const [selectedDeliveryIds, setSelectedDeliveryIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);




  const handleCheckChange = (record, checked) => {
    if (checked) {
      // If the checkbox is checked, add the ID to the array
      setSelectedDeliveryIds(prevState => [...prevState, record.key]);
    } else {
      // If the checkbox is unchecked, remove the ID from the array
      setSelectedDeliveryIds(prevState => prevState.filter(id => id !== record.key));
    }
  };

  const handleSelectAllClick = () => {
    setSelectAll(prev => {
      const newValue = !prev;
      if (newValue) {
        // If selectAll is being set to true, add all IDs to selectedDeliveryIds
        const allIds = dataSource.map(record => record.key);
        console.log(`All IDs: ${allIds}`);
        setSelectedDeliveryIds(allIds);
      } else {
        // If selectAll is being set to false, clear selectedDeliveryIds
        setSelectedDeliveryIds([]);
      }
      return newValue;
    });
  };


  const columns = [
    {
      title: <div style={{cursor: 'pointer'}} onClick={handleSelectAllClick}>Select</div>,
      dataIndex: 'select',
      render: (_, record) => <Checkbox checked={selectAll || selectedDeliveryIds.includes(record.key)} onChange={(e) => handleCheckChange(record, e.target.checked)} />,
    },
    ...Columns(),
  ].map((col) => {
    return {
      ...col,
      onCell: (record) => ({
        record,
        title: col.title,
      }),
    };
  });

  const confirmStatusChange = () => {
    Modal.confirm({
      title: 'Are you sure you want to change the status?',
      content: 'This action cannot be undone.',
      onOk: async () => {
        // Convert status to constant style (uppercase with underscores)
        const statusConstantStyle = status.toUpperCase().replace(/\s+/g, '_');
        console.log(`Status changed to ${statusConstantStyle}`);
        // Get the token from the cookie
        const token = Cookies.get('Authorization');

        console.log(`Selected delivery IDs: ${selectedDeliveryIds}`);

        // Send the new status and selected delivery ids to the server
        try {
          const response = await axios({
            method: 'post',
            url: '/deliveries/status/bulk',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
            data: {
              status: statusConstantStyle,
              deliveryIds: selectedDeliveryIds,
            }
          });
          // Handle response...
          console.log('Status change successful');
          // Refresh the list
          refreshTable();

        } catch (error) {
          console.log('Status change failed', error.toString());
        }
      },
    });
  };

  return (
      <div>
      <div className='' style={{padding:30}}>
        <h1>Delivery Status Change</h1>
        <Select defaultValue="" style={{ width: 300 }} onChange={setStatus}>
          <Select.Option value="DRAFT">Draft</Select.Option>
          <Select.Option value="PREPARING_FOR_SHIPMENT">Preparing for Shipment</Select.Option>
          <Select.Option value="READY_FOR_SHIPPING">Ready for Shipping</Select.Option>
          <Select.Option value="SHIPPING">Shipping</Select.Option>
          <Select.Option value="DELIVERED">Delivered</Select.Option>
          <Select.Option value="CANCELED">Canceled</Select.Option>
          <Select.Option value="PARTIALLY_SHIPPED">Partially Shipped</Select.Option>
        </Select>
        <Button type="primary" style={{marginRight:'10px'}} onClick={confirmStatusChange}>Confirm</Button>
        <Button type="primary" style={{marginRight:'10px'}} onClick={downloadExcel}>Excel Download</Button>
        <Button
            type="primary"
            onClick={() => {
              if (selectedDeliveryIds.length === 0) {
                alert('No deliveries selected. Please select at least one delivery.');
              } else {
                downloadSelectedExcel(selectedDeliveryIds);
              }
            }}
        >
          Selected Excel Download
        </Button>      </div>
          <div className='table-container'>
            <Table
              className='delivery-table'
              scroll={{ x: true }}
              rowClassName={() => 'editable-row'}
              bordered
              dataSource={dataSource}
              columns={columns}
              pagination={tablePagination}
              onChange={handleTableChange}
            />
          </div>
      </div>
  );
};
