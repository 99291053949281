import React, { useState, useEffect } from "react";
import { Card, Typography, List, Select, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { addCommas, getLanguageKey } from '../../common/Util';

import axios from 'axios';

const { Text } = Typography;
const { Option } = Select;

export default function OrderInfo({ detail }) {
    const [statuses, setStatuses] = useState();
    const [deliveryStatus, setDeliveryStatus] = useState("");

    const { t } = useTranslation();
  
    const getCompanies = async () => {
      const { data } = await axios.get(`/delivery-status`);
      setStatuses(data);
   }
  
    useEffect(() => {
      getCompanies();
      setDeliveryStatus(detail.status);
    }, []);

    const handleStatusChange = (value) => {
        setDeliveryStatus(value);
    };

    const updateDeliveryStatus = async () => {
        await axios.post(`/deliveries/${detail.id}/status`, { status: deliveryStatus })
        .then(() => { alert(t('common.message.status-change-complete')); })
        .catch(e => {
            alert(t('common.message.status-change-failed'));
            console.log(e);
        });
    }

    return (
        <Card title={ t(getLanguageKey('deliveries', detail.status)) }> 
            <List style={{marginBottom: 20}}>
                <List.Item className="grid-style">
                    <Text type="secondary">{ t('common.nickname') }</Text>
                    <p>{detail.account.nickName}</p>
                </List.Item>
                <List.Item className="grid-style">
                    <Text type="secondary">{ t('deliveries.destination') }</Text>
                    <p>{detail.addressName}</p>
                </List.Item>
                <List.Item className="grid-style">
                    <Text type="secondary">{ t('deliveries.recipient-name') }</Text>
                    <p>{detail.account.name}</p>
                </List.Item>
                <List.Item className="grid-style">
                    <Text type="secondary">{ t('deliveries.detail.address') }</Text>
                    <p>{detail.address}</p>
                </List.Item>
                <List.Item className="grid-style">
                    <Text type="secondary">{ t('deliveries.detail.address-detail') }</Text>
                    <p>{detail.addressDetail}</p>
                </List.Item>
                <List.Item className="grid-style">
                    <Text type="secondary">{ t('deliveries.detail.postal-number') }</Text>
                    <p>{detail.postalCode}</p>
                </List.Item>
                <List.Item className="grid-style">
                    <Text type="secondary">{ t('deliveries.detail.phone-number') }</Text>
                    <p>{detail.phoneNumber}</p>
                </List.Item>
                <List.Item className="grid-style">
                    <Text type="secondary">{ t('deliveries.detail.message') }</Text>
                    <p>{detail.message}</p>
                </List.Item>
                <List.Item className="grid-style">
                    <Text type="secondary">{ t('deliveries.create-time') }</Text>
                    <p>{detail.createdOn}</p>
                </List.Item>
                <List.Item className="grid-style">
                    <Text type="secondary">{ t('deliveries.status') }</Text>
                    <Select value={deliveryStatus} style={{ width: '70%' }} onChange={handleStatusChange}>
                        <Option value="">{ t('common.message.select') }</Option>
                        {statuses &&
                        statuses.map((status) => (
                            <Option key={status.name} value={status.name}>
                            { t(getLanguageKey('deliveries', status.name)) }
                            </Option>
                        ))}
                    </Select>
                </List.Item>
                <List.Item className="grid-style">
                    <Text type="secondary">{ t('deliveries.products-name') }</Text>
                    <p>{detail.productName}</p>
                </List.Item>
                <List.Item className="grid-style">
                    <Text type="secondary">{ t('products.brand') }</Text>
                    <p>{detail.brandName}</p>
                </List.Item>
                <List.Item className="grid-style">
                    <Text type="secondary">{ t('deliveries.detail.total-product-price') }</Text>
                    <p>{addCommas(detail.retailPrice)}</p>
                </List.Item>
                <List.Item className="grid-style">
                    <Text type="secondary">{ t('deliveries.detail.total-quantity') }</Text>
                    <p>{detail.qty}</p>
                </List.Item>
                <List.Item className="grid-style">
                    <Text type="secondary">{ t('deliveries.detail.product-image') }</Text>
                    <p>{detail.productImageURL}</p>
                </List.Item>
            </List>
            <Button type="primary" block onClick={updateDeliveryStatus}>{ t('common.save') }</Button>
        </Card>
    )
}