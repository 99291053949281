import { Table } from 'antd';
import React from 'react';

import Columns from './Columns';

export default function InquiryList({ dataSource, tablePagination, handleTableChange }) {
  const columns = Columns().map((col) => {
    return {
      ...col,
      onCell: (record) => ({
        record,
        title: col.title,
      }),
    };
  });

  return (
    <div className='table-container'>
      <Table
        className='inquiry-table'
        scroll={{ x: true }}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={tablePagination}
        onChange={handleTableChange}
      />
    </div>
  );
};