import React, { useState } from 'react';

import { Modal, Button, Form, Input, Switch, Row, Col } from 'antd';

import { useTranslation } from 'react-i18next';
import axios from 'axios';

export default function BannerModal({ category, getBannerList, showSize }) {
    const [open, setOpen] = useState(false);
    const [scan, setScan] = useState(false);
    const [active, setActive] = useState(false);

    const [form] = Form.useForm();

    const { t } = useTranslation();

    const handleFormSubmit = async (values) => {
        await axios.post(`/banners`, {
            category,
            ...values,
            scan,
            active
        })
        .then(async () => {
            form.resetFields();
            setScan(false);
            setActive(false);

            await getBannerList(1, showSize);
        });

        setOpen(false);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    return (
        <>
            <Button
                type="primary"
                style={{
                    marginBottom: 16,
                }}
                onClick={() => { setOpen(true); }}
            >
                Add { category } +
            </Button>
            <Modal
                title={ t("header.setting.children.mid-banner") }
                open={open}
                onCancel={handleCancel}
                footer={null}
            >
                <Form
                    name="customized_form_controls"
                    layout="inline"
                    form={form}
                    onFinish={handleFormSubmit}
                >
                    <Row gutter={8} style={{ marginBottom: 10, width: '100%' }}>
                        <Col md={24}>
                            <Form.Item
                                name="imageURL"
                                rules={[
                                {
                                    required: true,
                                    message: 'Please input your Image URL!',
                                },
                                ]}
                                style={{ marginBottom: 10 }}
                            >
                                <Input addonBefore={t('settings.banner.image-url')} type="text" />
                            </Form.Item>
                        </Col>
                        <Col md={24}>
                            <Form.Item
                                name="detailPageURL"
                                rules={[
                                {
                                    required: true,
                                    message: 'Please input your Detail Page URL!',
                                },
                                ]}
                                style={{ marginBottom: 10 }}
                            >
                                <Input addonBefore={t('settings.banner.detail-page-url')} type="text" />
                            </Form.Item>
                        </Col>
                        <Col md={8}>
                            <Form.Item
                                name="sort"
                                rules={[
                                {
                                    required: true,
                                    message: 'Please input your Sort!',
                                },
                                ]}
                                style={{ marginBottom: 10 }}
                            >
                                <Input addonBefore={t('settings.banner.sort')} type="text" />
                            </Form.Item>
                        </Col>
                        <Col md={8} style={{ marginTop: '3px' }}>
                            <span style={{ marginRight: '10px' }}>{t('settings.banner.scan')}</span>
                            <Switch checked={scan} onChange={(checked) => { setScan(checked) }} />
                        </Col>
                        <Col md={8} style={{ marginTop: '3px' }}>
                        <span style={{ marginRight: '10px' }}>{t('settings.banner.active')}</span>
                            <Switch checked={active} onChange={(checked) => { setActive(checked) }} />
                        </Col>
                    </Row>
                    <Row gutter={3}>
                        <Col>
                            <Form.Item>
                                <Button>
                                {t('common.delete')}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                {t('common.save')}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}