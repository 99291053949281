import React from 'react';
import { addCommas, roundToFive } from '../../common/Util';
import { useTranslation } from 'react-i18next';

export default function Columns (props) {
  const { t } = useTranslation();

  const columns = [ 
    { 
      title: t('products.category'), 
      dataIndex: 'groupName', 
    },
    { 
      title: t('products.count'), 
      dataIndex: 'drawnCount',
    },
    { 
      title: t('products.probability'), 
      dataIndex: 'drawnProbability',
      render: (val) => <span>{ roundToFive(val) }</span>,
    },
    { 
      title: t('products.total-retail'), 
      dataIndex: 'drawnTotalConsumerPrice',
      render: (val) => <span>{ addCommas(val) }</span>,
    },
    { 
      title: t('products.total-cost'), 
      dataIndex: 'drawnTotalPurchasePrice',
      render: (val) => <span>{ addCommas(val) }</span>,
    },
  ]; 
 
  return columns; 
};
