import React, { useState, useEffect } from 'react';
import { Line } from '@ant-design/plots';
import { Card, Col, Row } from 'antd';

import OrderSearch from '../component/orders/list/OrderSearch';
import OrderList from '../component/orders/list/OrderList';

import axios from 'axios';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export default function Order() {
    const [selectedRows, setSelectedRows] = useState();
    const [dataSource, setDataSource] = useState();
    const [datePeriod, setDatePeriod] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState(null);
    const [orderStatus, setOrderStatus] = useState('');
    const [searching, setSearching] = useState(false);
    const [chartData, setChartData] = useState([]);
    const [summaryData, setSummaryData] = useState({});
    const [tablePagination, setTablePagination] = useState({
      pageSize: 20,
      total: 0,
      current: 1,
      position: ['bottomLeft'],
      pageSizeOptions: ['10', '20', '30', '40'],
      showSizeChanger: true
    })
    const [prevSorter, setPrevSorter] = useState({});

    const { t } = useTranslation();

    const getSalesStatistics = async (nickName, startDate, endDate) => {
        try {
            const { data } = await axios.get('/admin/daily-sales-statistics', {
                params: {
                    nickName: nickName? nickName : null,
                    startDate: startDate ? moment(startDate).format("YYYY-MM-DDTHH:mm:ss") : null,
                    endDate: endDate ? moment(endDate).format("YYYY-MM-DDTHH:mm:ss") : null,
                }
            });
            return data;
        } catch (error) {
            console.error(error.message);
        }
    };

    useEffect(() => {
        getSalesStatistics(searchKeyword, datePeriod[0], datePeriod[1])
            .then(data => {
                // 여기서 data를 사용하여 차트를 그립니다.
                setChartData(data.flatMap(item => ([
                    { date: item.date, category: 'sales', value: item.sales },
                    { date: item.date, category: 'costs', value: item.costs },
                    { date: item.date, category: 'profits', value: item.profits },
                ])));
            });
    }, [searchKeyword, datePeriod]);

    const getSummarySalesStatistics = async (nickName, startDate, endDate) => {
        try {
            const { data } = await axios.get('/admin/summary-sales-statistics', {
                params: {
                    nickName: nickName? nickName : null,
                    startDate: startDate ? moment(startDate).format("YYYY-MM-DDTHH:mm:ss") : null,
                    endDate: endDate ? moment(endDate).format("YYYY-MM-DDTHH:mm:ss") : null,
                }
            });
            return data;
        } catch (error) {
            console.error(error.message);
        }
    };

    useEffect(() => {
        getSummarySalesStatistics(searchKeyword, datePeriod[0], datePeriod[1])
            .then(data => {
                setSummaryData(data);
            });
    }, [searchKeyword, datePeriod]);

    const getOrderList = async (page, size, sort, keyword) => {
      try {
        const paramObj = {
          params: { 
            page: page-1, 
            size: size ? size : tablePagination.pageSize,
            sort: sort ? sort : 'id,desc',
            startDate: datePeriod[0]? moment(datePeriod[0]).format("YYYY-MM-DDTHH:mm:ss") : null,
            endDate: datePeriod[1]? moment(datePeriod[1]).format("YYYY-MM-DDTHH:mm:ss") : null,
            query: keyword ? keyword : null,
            status: orderStatus ? orderStatus : null
          } 
        }
  
        const { data } = await axios.get(`/orders/search/any`, paramObj);

        const productOrigin = data.content.map((data) => {
          return { ...data, nickName: data.account.nickName, key: data.id };
        });
  
        setTablePagination(current => ({
          ...current,
          total: data.totalElements,
          current: page
        }))
        setDataSource(productOrigin);
      } catch (error) {
        console.error(error.message);
      }
    };


    useEffect(() => {
      getOrderList(tablePagination.current, tablePagination.pageSize, 'id,desc', searchKeyword);
    }, []);

    const rowSelection = {
      onChange: (keys, rows) => {
        setSelectedRows(rows);
      },
    };

    const searchOrder = async (keyword) => {
        let field = prevSorter.field;
        if (field === 'nickName') {
            field = 'account.nickName';
        } else if (field === 'name') {
            field = 'account.name';
        }

        setSearchKeyword(keyword);
        setSearching(true); // Add this line to disable the search button

        try {
            await getOrderList(1, tablePagination.pageSize, 
                `${field ? field : 'id'},${prevSorter.order === 'ascend' ? 'asc' : 'desc'}`,
                keyword);
        } finally {
            setSearching(false); // Add this line to re-enable the search button
        }
    }

    useEffect(() => {
        let field = prevSorter.field;
        if (field === 'nickName') {
          field = 'account.nickName';
        } else if (field === 'name') {
          field = 'account.name';
        }
        if (searching) {
            (async () => {
                try {
                    await getOrderList(1, tablePagination.pageSize
                        `${field ? field : 'id'},${prevSorter.order === 'ascend' ? 'asc' : 'desc'}`,
                        searchKeyword);
                } finally {
                    setSearching(false);
                }
            })();
        }
    }, [searching, searchKeyword]); // Add 'searchKeyword' to the dependency array


    useEffect(() => {
        let field = prevSorter.field;
        if (field === 'nickName') {
          field = 'account.nickName';
        } else if (field === 'name') {
          field = 'account.name';
        }
        getOrderList(1, tablePagination.pageSize,
            `${field ? field : 'id'},${prevSorter.order === 'ascend' ? 'asc' : 'desc'}`,
            searchKeyword);
    }, [orderStatus]);


    const config = {
        data: chartData,
        xField: 'date',
        yField: 'value',
        colorField: 'category',
        style: {
            lineWidth: 2,
        }

    };

    const handleTableChange = async (pagination, filters, sorter) => {
        let field = sorter.field;
        if (field === 'nickName') {
            field = 'account.nickName';
        }
        setTablePagination(current => ({
          ...current,
          current: pagination.current,
          pageSize: pagination.pageSize
        }))

        // 정렬 기준이 변경되었는지 확인
        const isSorterChanged = prevSorter.field !== sorter.field || prevSorter.order !== sorter.order;
    
        // 정렬 기준이 변경되었다면 페이지네이션을 첫 페이지로 설정, 그렇지 않으면 현재 페이지 사용
        const currentPage = isSorterChanged ? 1 : pagination.current;

        await getOrderList(currentPage, pagination.pageSize,
            `${field ? field : 'id'},${sorter.order === 'ascend' ? 'asc' : 'desc'}`,
            searchKeyword);
    
        // 현재 정렬 기준을 이전 정렬 기준으로 저장
        setPrevSorter(sorter);
    };

    return (
        <>
            <Line {...config} />
            <Row gutter={16}>
                {
                    summaryData &&
                    <>
                        <Col span={4}>
                            <Card title="Total Sales">{summaryData.totalSales}</Card>
                        </Col>
                        <Col span={4}>
                            <Card title="Sales">{summaryData.sales}</Card>
                        </Col>
                        <Col span={4}>
                            <Card title="Cost">{summaryData.cost}</Card>
                        </Col>
                        <Col span={4}>
                            <Card title="Total Inventory">{summaryData.totalInventory}</Card>
                        </Col>
                        <Col span={4}>
                            <Card title="Point Sales">{summaryData.pointSales}</Card>
                        </Col>
                        <Col span={4}>
                            <Card title="Profit A">{summaryData.profitA}</Card>
                        </Col>
                        <Col span={4}>
                            <Card title="Profit B">{summaryData.profitB}</Card>
                        </Col>
                        <Col span={4}>
                            <Card title="Box Opened Count">{summaryData.boxOpenedCount}</Card>
                        </Col>
                        <Col span={4}>
                            <Card title="Delivery Count">{summaryData.deliveryCount}</Card>
                        </Col>
                        <Col span={4}>
                            <Card title="Point Payback Count">{summaryData.pointPaybackCount}</Card>
                        </Col>
                    </>
                }
            </Row>
            <OrderSearch selectedRows={selectedRows}
                setDatePeriod={setDatePeriod}
                searchOrder={searchOrder}
                orderStatus={orderStatus}
                setOrderStatus={setOrderStatus}
                getOrderList={getOrderList}
            />
            <OrderList
                rowSelection={rowSelection}
                dataSource={dataSource}
                setDataSource={setDataSource}
                tablePagination={tablePagination}
                searching={searching}
                handleTableChange={handleTableChange}
            />
        </>
    )
}