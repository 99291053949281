import { Typography } from 'antd';
import React, { useState, useEffect } from 'react';

import InquirySearch from '../component/inquiry/list/InquirySearch';
import InquiryList from '../component/inquiry/list/InquiryList';

import axios from 'axios';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

export default function Inquiry() {
    const [dataSource, setDataSource] = useState();
    const [inquiryStatus, setInquiryStatus] = useState(null);
    const [searchKeyword, setSearchKeyword] = useState(null);
    const [tablePagination, setTablePagination] = useState({
        pageSize: 20,
        total: 0,
        current: 1,
        position: ['bottomLeft'],
        pageSizeOptions: ['10', '20', '30', '40'],
        showSizeChanger: true
    })
    const [prevSorter, setPrevSorter] = useState({});

    const { t } = useTranslation();

    useEffect(() => {
        let field = prevSorter.field;
        if (field === 'customer') {
            field = 'customer.nickName';
        }
        getInquiryList(tablePagination.current, tablePagination.pageSize, inquiryStatus,
            `${field ? field : 'id'},${prevSorter.order === 'ascend' ? 'asc' : 'desc'}`, searchKeyword);
    }, [inquiryStatus]);

    const getInquiryList = async (page, size, status, sort, keyword) => {
        try {
            const { data } = await axios.get('/inquiries/search/any', { 
                params: {
                    status,
                    page: page-1,
                    size,
                    sort: sort ? sort : 'id,desc',
                    query: keyword ? keyword : null
                }
            });

            const origin = data.content.map((data) => {
                return { ...data, key: data.id };
            });

            setTablePagination(current => ({
                ...current,
                total: data.totalElements,
                current: page
            }))
            setDataSource(origin);
        } catch (error) {
            console.error(error.message);
        }
    };

    const handleTableChange = async (pagination, filters, sorter) => {
        let field = prevSorter.field;
        if (field === 'customer') {
            field = 'customer.nickName';
        }
        setTablePagination(current => ({
          ...current,
          current: pagination.current,
          pageSize: pagination.pageSize
        }))

        // 정렬 기준이 변경되었는지 확인
        const isSorterChanged = prevSorter.field !== sorter.field || prevSorter.order !== sorter.order;
    
        // 정렬 기준이 변경되었다면 페이지네이션을 첫 페이지로 설정, 그렇지 않으면 현재 페이지 사용
        const currentPage = isSorterChanged ? 1 : pagination.current;

        await getInquiryList(currentPage, pagination.pageSize, inquiryStatus,
            `${field ? field : 'id'},${sorter.order === 'ascend' ? 'asc' : 'desc'}`,
            searchKeyword);
    
        // 현재 정렬 기준을 이전 정렬 기준으로 저장
        setPrevSorter(sorter);
    };

    const onSearchHandler = async (keyword) => {
        let field = prevSorter.field;
        if (field === 'customer') {
            field = 'customer.nickName';
        }
        setSearchKeyword(keyword);
        await getInquiryList(1, tablePagination.pageSize, inquiryStatus,
            `${field ? field : 'id'},${prevSorter.order === 'ascend' ? 'asc' : 'desc'}`, keyword);
    }

    return (
        <>
            <Title level={2}>{ t("header.inquiry") }</Title>
            <InquirySearch
                    inquiryStatus={inquiryStatus}
                    setInquiryStatus={setInquiryStatus}
                    onSearchHandler={onSearchHandler}
            />
            <InquiryList
                    dataSource={dataSource}
                    setDataSource={setDataSource}
                    tablePagination={tablePagination}
                    handleTableChange={handleTableChange}
            />
        </>
    )
}