import React from 'react';

import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getLanguageKey, dateFormat } from '../../common/Util';

const { Text } = Typography;

export default function Columns () {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('inquiries.id'),
      dataIndex: 'id',
      render: (val, record) => (
        <>
          <Link to={`/inquiry-detail?id=${val}`}>{ val }</Link>
        </>
      ),
      sorter: true,
    },
    {
      title: t('inquiries.type'),
      dataIndex: 'type',
      sorter: true,
    },
    {
      title: t('inquiries.title'),
      dataIndex: 'title',
      sorter: true,
    },
    {
      title: t('inquiries.contents'),
      dataIndex: 'contents',
      render: (val, record) => (
        <>
          <Text ellipsis>
            {val.length > 20 ? `${val.substring(0, 20)}...` : val}
          </Text>
        </>
      ),
    },
    {
      title: t('inquiries.customer'),
      dataIndex: 'customer',
      render: (val) => <Link to={`/account-detail?id=${val.id}`}>{val.nickName}</Link>,
      sorter: true,
    },
    {
      title: t('inquiries.answer-title'),
      dataIndex: 'answerTitle',
      sorter: true,
    },
    {
      title: t('inquiries.status'),
      dataIndex: 'status',
      render: (val) => <span>{ t(getLanguageKey('inquiries', val)) }</span>,
      sorter: true,
    },
    {
      title: t('inquiries.create-date-time'),
      dataIndex: 'createDateTime',
      render: (val) => <span>{ dateFormat(val) }</span>,
      sorter: true,
    }
  ];

  return columns;
};
