import React from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { dateFormat, getLanguageKey } from '../../common/Util';

export default function Columns () {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('deliveries.number'),
      dataIndex: 'id',
      render: (val, record) => (
        <>
          <Link to={`/delivery-detail?id=${val}`}>{ val }</Link>
        </>
      ),
      sorter: true,
    },
    {
      title: t('deliveries.create-time'),
      dataIndex: 'createdOn',
      render: (val) => <span>{ dateFormat(val) }</span>, 
      sorter: true,
    },
    {
      title: t('deliveries.products-name'),
      dataIndex: 'productName',
      sorter: true,
    },
    {
      title: t('deliveries.destination'),
      dataIndex: 'addressName',
    },
    {
      title: t('common.nickname'),
      dataIndex: 'nickName',
      render: (val, record) => <Link to={`/account-detail?id=${record.account.id}`}>{record.account.nickName}</Link>,
      sorter: (a, b) => a.nickName && b.nickName ? a.nickName.localeCompare(b.nickName) : 0,
    },
    {
      title: t('deliveries.recipient-name'),
      dataIndex: 'name',
      render: (val, record) => <span>{ record.account.name }</span>, 
      sorter: (a, b) => a.name && b.name ? a.name.localeCompare(b.name) : 0,
    },
    {
      title: t('deliveries.total-count'),
      dataIndex: 'qty',
    },
    {
      title: t('deliveries.status'),
      dataIndex: 'status',
      render: (val) => <span>{ t(getLanguageKey('deliveries', val)) }</span>,
      sorter: true,
    },
    {
      title: t('deliveries.is-mobile'),
      dataIndex: 'isMobileCoupon',
    },
  ];

  return columns;
};
