import React from 'react';
import { Button, Table } from 'antd';
import { useNavigate } from 'react-router-dom';

import Columns from './Columns';

export default function CouponList({ dataSource, tablePagination, handleTableChange }) {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/coupon-create');
  };

  const columns = Columns().map((col) => {
    return {
      ...col,
      onCell: (record) => ({
        record,
        title: col.title,
      }),
    };
  });

  return (
    <div className='table-container'>
      <Button
        type="primary"
        style={{ float: 'right', marginBottom: '15px' }}
        onClick={handleButtonClick}
      >
        New Coupon
      </Button>
      <Table
        className='coupon-table'
        scroll={{ x: true }}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={tablePagination}
        onChange={handleTableChange}
      />
    </div>
  );
};