import React from 'react';
import { Card, Typography, Input, Image } from 'antd';
import { useTranslation } from 'react-i18next';

import { dateFormat } from '../../common/Util';

const { Text } = Typography;

export default function Inquiry({ inquiryData }) {
    const { t } = useTranslation();

    const mb20 = {
        marginBottom: '20px'
    };
  
    return (
        <>
        {
            inquiryData && (
                <Card>
                  <div style={mb20}>
                      <Text type="secondary">{t('inquiries.title')}</Text>
                      <Input disabled={true} value={inquiryData.title} />
                  </div>
                  <div style={mb20}>
                      <Text type="secondary">{t('inquiries.type')}</Text>
                      <Input disabled={true} value={inquiryData.type} />
                  </div>
                  <div style={mb20}>
                      <Text type="secondary">{t('inquiries.phone')}</Text>
                      <Input disabled={true} value={inquiryData.phoneNumber} />
                  </div>
                  <div style={mb20}>
                      <Text type="secondary">{t('inquiries.contents')}</Text>
                      <Input.TextArea rows={10} readOnly={true} value={inquiryData.contents} />
                  </div>
                  <div style={mb20}>
                      <Text type="secondary">{t('inquiries.create-date-time')}</Text>
                      <Input disabled={true} value={dateFormat(inquiryData.createDateTime)} />
                  </div>
                  <div style={mb20}>
                    <Text type="secondary">{t('inquiries.image')}</Text><br />
                    { inquiryData.figure1 && <Image
                            src={inquiryData.figure1}
                            alt="My Image"
                        />
                    }
                    { inquiryData.figure2 && <Image
                            src={inquiryData.figure2}
                            alt="My Image"
                        />
                    }
                    { inquiryData.figure3 && <Image
                            src={inquiryData.figure3}
                            alt="My Image"
                        />
                    }
                  </div>
                </Card>
            )
        }
        </>
    );
  };