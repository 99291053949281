import { Table } from 'antd';
import React from 'react';

import Columns from './Columns';

import './order.css';

export default function OrderList({ rowSelection, dataSource, tablePagination, searching, handleTableChange }) {
  const columns = Columns().map((col) => {
    return {
      ...col,
      onCell: (record) => ({
        record,
        title: col.title,
      }),
    };
  });

  return (
    <div className='table-container'>
      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        className='order-table'
        scroll={{ x: true }}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        onChange={handleTableChange}
        columns={columns}
        pagination={tablePagination}
        loading={searching}
      />
    </div>
  );
};