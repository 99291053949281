// src/PaymentForm.js
import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';

const stripePromise = loadStripe('pk_test_51P4cg7P4B0B24QmuXba4tPgasbYPoeTCWGyHDQphCi4PkMphIoBpnoq4WlOO62W85YZneb5cHDWJziylMLswQftI0082azONVc'); // Replace with your actual publishable key

const PaymentForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [paymentSucceeded, setPaymentSucceeded] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });

        if (!error) {
            const { id } = paymentMethod;
            try {
                const response = await axios.post('/payments/create-payment-intent', {
                    amount: 1000, // Amount in cents
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const paymentIntent = response.data;

                const { error: stripeError, paymentIntent: confirmedPaymentIntent } = await stripe.confirmCardPayment(paymentIntent.clientSecret, {
                    payment_method: id,
                });

                if (!stripeError) {
                    setPaymentSucceeded(true);
                } else {
                    console.log('Stripe confirm error:', stripeError.message);
                    setErrorMessage(stripeError.message);
                }
            } catch (error) {
                console.log('Payment failed:', error.response ? error.response.data : error.message);
                setErrorMessage(error.response ? error.response.data.error.message : error.message);
            }
        } else {
            console.log('Stripe error:', error.message);
            setErrorMessage(error.message);
        }
    };

    return (
        <div>
            {paymentSucceeded ? (
                <h2>Payment Successful!</h2>
            ) : (
                <form onSubmit={handleSubmit}>
                    <CardElement />
                    <button type="submit" disabled={!stripe}>
                        Pay
                    </button>
                    {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                </form>
            )}
        </div>
    );
};

const App = () => (
    <Elements stripe={stripePromise}>
        <PaymentForm />
    </Elements>
);

export default App;
