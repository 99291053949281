import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Card, Image, Button, Checkbox, Form, Input } from 'antd';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Cookies from 'js-cookie';
import axios from 'axios';
import { jwtVerify } from 'jose';

import './login.css'

export default function Login() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onFinish = async (values) => {
    await axios.post('/auth/login', values)
    .then(async ({ data }) => {
      const key = new TextEncoder().encode(process.env.REACT_APP_JWT_KEY);
      const { payload } = await jwtVerify(data, key, {
        algorithms: ['HS512'] // 알고리즘 지정
      });
      if (payload.role == 'ROLE_MEMBER') {
        alert("I can't access the admin page.");
      } else {
        Cookies.set('Authorization', data);
      }
    })
    .catch(e => {
      if (e.response && e.response.status === 401) {
        const errorMessage = e.response.data.message;
        alert(errorMessage);
        console.error('Authentication error:', errorMessage);
      } else {
        alert("I can't access the admin page.");
        console.error('An error occurred:', e.message);
      }
    });
    navigate('/');
  };
  return (
    <div className='login-container'>
      <Card style={{ width: 500, padding: 48 }}>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Image
              style={{ marginBottom: 48 }}
              src="./logo.jpg"
            />
          </div>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input your Email!',
              },
            ]}
          >
            <Input type="email" prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your Password!',
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <a className="login-form-forgot" href="">
              Forgot password
            </a>
          </Form.Item>

          <Form.Item>
            <Button type="primary" block htmlType="submit" className="login-form-button">
              {t("login")}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};