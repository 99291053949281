import './layout.css';
import React, { useEffect, useState } from 'react';
import { Layout, Menu, Button, Dropdown } from 'antd';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LogoutOutlined } from '@ant-design/icons';
import { GlobalOutlined } from '@ant-design/icons';

import Cookies from 'js-cookie';
import { jwtVerify } from 'jose';

const { Header } = Layout;

export default function HeaderComp () {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [menus, setMenus] = useState(null);

  useEffect(() => {
    const getToken = async () => {
      const token = Cookies.get('Authorization');
      const key = new TextEncoder().encode(process.env.REACT_APP_JWT_KEY);
      const { payload } = await jwtVerify(token, key, {
        algorithms: ['HS512'] // 알고리즘 지정
      });

      const allMenu = [
        {
          label: t("header.setting.label"),
          key: 'setting',
          children: [
            {
              label: <Link to="/mid-banner">{t("header.setting.children.mid-banner")}</Link>,
              key: 'setting-sub1'
            },
            {
              label: <Link to="/top-banner">{t("header.setting.children.top-banner")}</Link>,
              key: 'setting-sub2'
            },
            {
              label: <Link to="/popup-banner">{t("header.setting.children.popup-banner")}</Link>,
              key: 'setting-sub3'
            },
            {
              label: <Link to="/terms-of-service">{t("header.setting.children.terms-of-service")}</Link>,
              key: 'setting-sub4'
            },
            {
              label: <Link to="/faq">{t("header.setting.children.faq")}</Link>,
              key: 'setting-sub5'
            },
            {
              label: <Link to="/box-settings">{t("header.setting.children.box-settings")}</Link>,
              key: 'setting-sub6'
            },
            {
              label: <Link to="/personal-rules">{t("header.setting.children.personal-rules")}</Link>,
              key: 'setting-sub7'
            },
            {
              label: <Link to="/integrated-rules">{t("header.setting.children.integrated-rules")}</Link>,
              key: 'setting-sub8'
            },
          ],
        },
        {
          label: <Link to="/products">{t("header.product")}</Link>,
          key: 'product',
        },
        {
          label: <Link to="/orders">{t("header.order")}</Link>,
          key: 'order',
        },
        {
          label: <Link to="/inquiry">{t("header.inquiry")}</Link>,
          key: 'inquiry',
        },
        {
          label: <Link to="/accounts">{t("header.account")}</Link>,
          key: 'account',
        },
        {
          label: <Link to="/delivery">{t("header.delivery")}</Link>,
          key: 'delivery',
        },
        {
          label: <Link to="/coupons">{t("header.coupon")}</Link>,
          key: 'coupon',
        },
        {
          label: <Link to="/inventory">{t("header.inventory")}</Link>,
          key: 'inventory',
        },
        {
          label: <Link to="/permission">{t("header.permission")}</Link>,
          key: 'permission',
        }
      ];

      if (payload.subRole === 'ROLE_SUB_SUBMASTER') {
        setMenus(
          allMenu.filter(menu => (
            menu.key == 'order'
            || menu.key == 'inquiry'
            || menu.key == 'account'
            || menu.key == 'delivery'
            || menu.key == 'coupon'
            || menu.key == 'inventory'
          ))
        )
      } else if (payload.subRole === 'ROLE_SUB_DELIVERY') {
        setMenus(
          allMenu.filter(menu => (
            menu.key == 'order'
            || menu.key == 'delivery'
            || menu.key == 'inventory'
          ))
        )
      } else if (payload.subRole === 'ROLE_SUB_INQUIRY') {
        setMenus(
          allMenu.filter(menu => (
            menu.key == 'order'
            || menu.key == 'inquiry'
            || menu.key == 'inventory'
          ))
        )
      } else {
        // 전체 메뉴를 다 볼 수 있는 ROLE_SUB_MASTER
        setMenus(allMenu);
      }
    }
    getToken();    
  }, [i18n.language]);

  const languages = [
    {
      key: 'en',
      label: t("language.english"),
      onClick: () => { handleChangeLanguage('en') }
    },
    {
      key: 'ko',
      label: t("language.korean"),
      onClick: () => { handleChangeLanguage('ko') }
    }
  ];

  const handleLogout = () => {
    Cookies.remove('Authorization');
    navigate('/login');
  };

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    Cookies.set('language', lang);
  }

  return (
    <div className="header-wrapper"> {/* Add the parent wrapper */}
      <Header>
        <div className="logo" />
        <Menu theme="dark" mode="horizontal" style={{minWidth: '1200px'}} items={menus} />
      </Header>

      <div className="header-icons">
        <Dropdown
          menu={{
            items: languages,
          }}
          trigger={['click']}
        >
          <Button icon={<GlobalOutlined />} />
        </Dropdown>
        <Button icon={<LogoutOutlined />} onClick={handleLogout} />
      </div>
    </div>
  );
};
