import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Login() {
  const { t } = useTranslation();

  return (
    <>
        <p>개발 진행 중입니다</p>
    </>
  );
};