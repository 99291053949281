import React from 'react';
import { Table } from 'antd';

import Columns from './Columns';

export default function SimulationList({ simulationData }) {
  return (
    <div className='table-container'>
    {
      simulationData && <Table
        scroll={{ x: true }}
        bordered
        dataSource={simulationData.productSimulations}
        columns={Columns()}
        pagination={false}
      />
    }
    </div>
  );
};