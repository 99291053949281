import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Col, Row } from 'antd';

import DeliveryInfo from '../component/delivery/detail/DeliveryInfo';
import DeliveryItem from '../component/delivery/detail/DeliveryItem';
import DeliveryInvoice from '../component/delivery/detail/DeliveryInvoice';

import axios from 'axios';

export default function DeliveryDetail() {
    const [selectedRows, setSelectedRows] = useState();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [deliveryDetailData, setDeliveryDetailData] = useState();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const getDeliveryDetail = async (id) => {
        try {
          const { data } = await axios.get(`/admin/deliveries/${id}`);
          const deliveryItems = data.deliveryItems.map((item) => ({ ...item, key: item.id }));

          setDeliveryDetailData({ ...data, deliveryItems });
        } catch (error) {
          console.error(error.message);
        }
    };

    useEffect(() => {
        getDeliveryDetail(searchParams.get('id'));
    }, []);

    const rowSelection = {
        selectedRowKeys,
        onChange: (keys, rows) => {
          setSelectedRowKeys(keys);
          setSelectedRows(rows);
        },
    };

    const updateInvoice = async (invoice, successEvt) => {
        await axios.put(`/admin/delivery-items`, invoice)
        .then(async () => {
            await getDeliveryDetail(searchParams.get('id'));
            await setSelectedRowKeys([]);
            await successEvt();
        })
        .catch((e) => { console.log(e) })
    }

    return (
        <>
            <Row gutter={16} style={{ marginBottom: 20 }}>
                <Col span={8}>
                    { deliveryDetailData && <DeliveryInfo detail={deliveryDetailData} /> }
                </Col>
                <Col span={16}>
                    { deliveryDetailData && <DeliveryInvoice selectedRowKeys={selectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} selectedRows={selectedRows} updateInvoice={updateInvoice} /> }
                    { deliveryDetailData && <DeliveryItem rowSelection={rowSelection} deliveryItems={deliveryDetailData.deliveryItems} /> }
                </Col>
            </Row>
        </>
    )
}