import React, { useState, useEffect } from 'react';

import DeliverySearch from '../component/delivery/list/DeliverySearch';
import DeliveryList from '../component/delivery/list/DeliveryList';

import axios from 'axios';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Cookies from "js-cookie";
import { saveAs } from 'file-saver';


export default function Delivery() {
  const [dataSource, setDataSource] = useState();
  const [datePeriod, setDatePeriod] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [deliveryStatus, setDeliveryStatus] = useState('');
  const [tablePagination, setTablePagination] = useState({
    pageSize: 20,
    total: 0,
    onChange: async (page, size) => {
      setTablePagination(current => ({
        ...current,
        current: page,
        pageSize: size
      }))
    },
    current: 1,
    position: ['bottomLeft'],
    pageSizeOptions: ['10', '20', '30', '40'],
    showSizeChanger: true
  })
  const [prevSorter, setPrevSorter] = useState({});

  const { t } = useTranslation();

  const getDeliveryList = async (page, size, sort, keyword) => {

    try {
      const keywordQuery = keyword ? keyword : searchKeyword;
      const { data } = await axios.get('/admin/deliveries', {
        params: {
          page: page-1,
          size: size ? size : tablePagination.pageSize,
          sort: sort ? sort : 'id,desc',
          startDate: datePeriod[0]? moment(datePeriod[0]).format("YYYY-MM-DDTHH:mm:ss") : null,
          endDate: datePeriod[1]? moment(datePeriod[1]).format("YYYY-MM-DDTHH:mm:ss") : null,
          status: deliveryStatus ? deliveryStatus : null,
          query: keywordQuery ? keywordQuery : null
        }
      });

      const deliveryOrigin = data.content.map((data) => {
        return { ...data, key: data.id };
      });

      setTablePagination(current => ({
        ...current,
        total: data.totalElements,
        current: page
      }))
      setDataSource(deliveryOrigin);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    let field = prevSorter.field;
    if (field === 'nickName') {
      field = 'account.nickName';
    } else if (field === 'name') {
      field = 'account.name';
    }
    getDeliveryList(1, tablePagination.pageSize,
      `${field ? field : 'id'},${prevSorter.order === 'ascend' ? 'asc' : 'desc'}`);
  }, [deliveryStatus]);
  const refreshTable = async () => {
    if (datePeriod.length <= 0) {
      alert(t('common.message.select-period'));
      return;
    }

    let field = prevSorter.field;
    if (field === 'nickName') {
      field = 'account.nickName';
    } else if (field === 'name') {
      field = 'account.name';
    }
    getDeliveryList(1, tablePagination.pageSize,
        `${field ? field : 'id'},${prevSorter.order === 'ascend' ? 'asc' : 'desc'}`);
  }

  const searchDelivery = async (keyword) => {
    if (datePeriod.length <= 0) {
        alert(t('common.message.select-period'));
        return;
    }

    setSearchKeyword(keyword);

    let field = prevSorter.field;
    if (field === 'nickName') {
      field = 'account.nickName';
    } else if (field === 'name') {
      field = 'account.name';
    }
    getDeliveryList(1, tablePagination.pageSize,
      `${field ? field : 'id'},${prevSorter.order === 'ascend' ? 'asc' : 'desc'}`, keyword);
  }

  const handleTableChange = async (pagination, filters, sorter) => {
    let field = sorter.field;
    if (field === 'nickName') {
      field = 'account.nickName';
    } else if (field === 'name') {
      field = 'account.name';
    }
    setTablePagination(current => ({
      ...current,
      current: pagination.current,
      pageSize: pagination.pageSize
    }))

    // 정렬 기준이 변경되었는지 확인
    const isSorterChanged = prevSorter.field !== sorter.field || prevSorter.order !== sorter.order;

    // 정렬 기준이 변경되었다면 페이지네이션을 첫 페이지로 설정, 그렇지 않으면 현재 페이지 사용
    const currentPage = isSorterChanged ? 1 : pagination.current;

    await getDeliveryList(currentPage, pagination.pageSize,
        `${field ? field : 'id'},${sorter.order === 'ascend' ? 'asc' : 'desc'}`);

    // 현재 정렬 기준을 이전 정렬 기준으로 저장
    setPrevSorter(sorter);
  };

  const downloadExcel = async () => {
    try {
      const token = Cookies.get('Authorization');
      const response = await axios.get('/deliveries/export/date', {
        params: {
          startDate: moment(datePeriod[0]).format('YYYY-MM-DD'),
          endDate:  moment(datePeriod[1]).format('YYYY-MM-DD'),
        },
        headers: {
          'accept': '*/*',
          'Authorization': `Bearer ${token}`,
        },
        responseType: 'blob', // Important
      });

      const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
      saveAs(blob, moment(datePeriod[0]).format('YYYY-MM-DD')+"-"+ moment(datePeriod[1]).format('YYYY-MM-DD')+'deliveries.xlsx');
    } catch (error) {
      console.error(error);
    }
  };

  const downloadSelectedExcel = async (selectedDeliveryIds) => {
    try {
      const token = Cookies.get('Authorization');
      const response = await axios({
        method: 'post',
        url: '/deliveries/export/selected',
        headers: {
          'accept': '*/*',
          'Authorization': `Bearer ${token}`,
        },
        data: selectedDeliveryIds, // 변경된 부분
        responseType: 'blob', // Important
      });

      const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
      saveAs(blob, 'selected_deliveries.xlsx');
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <>
      <DeliverySearch
        setDatePeriod={setDatePeriod}
        searchDelivery={searchDelivery}
        setDeliveryStatus={setDeliveryStatus}
      />
      <DeliveryList
        dataSource={dataSource}
        tablePagination={tablePagination}
        handleTableChange={handleTableChange}
        refreshTable={refreshTable}
        downloadExcel={downloadExcel}
        downloadSelectedExcel={downloadSelectedExcel}
      />
    </>
  );
};
