import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form, Input, Select, Checkbox, Button, DatePicker } from 'antd';

import RangePicker from '../../common/RangePicker100';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import axios from 'axios';

import './form.css'

const { Option } = Select;

export default function CouponForm({ editType }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [boxList, setBoxList] = useState([]);
  const [searchParams] = useSearchParams();
  
  const [couponInput, setCouponInput] = useState({
    code: null,
    image: null,
    description: null,
    validFrom: null,
    validTo: null,
    totalIssued: null,
    grantPoints: false,
    usedCount: null,
    points: null,
    grantBox: false,
    boxCode: null,
    active: false
  });

  const getBoxList = async () => {
    try {
      const { data } = await axios.get('/box-items', {
        params: {
          pageNumber: 0,
          pageSize: 100,
          sortBy: 'id',
        },
      });
      setBoxList(data.content);
    } catch (error) {
      console.error(error.message);
    }
  };

  const getCouponInfo = async (id) => {
    try {
      const { data } = await axios.get(`/coupons/${id}`);
      setCouponInput(data);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    getBoxList();
    if (editType == "update") {
        getCouponInfo(searchParams.get('id'));
    }
  }, []);

  const onCouponInput = (e) => {
    const { name, value } = e.target;
    setCouponInput(current => ({ ...current, [name]: value }))
  }

  const onBoxcodeSelect = (select) => {
    setCouponInput(current => ({ ...current, boxCode: select }))
  }

  const onTypeCheck = (type) => {
    setCouponInput(current => type === 'point' ?
        { ...current, grantPoints: true, grantBox: false }
        : { ...current, grantPoints: false, grantBox: true }
    )
  }

  const onActive = () => {
    setCouponInput(current => ({ ...current, active: !current.active }))
  }

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
        setCouponInput(current => ({ ...current,
            validFrom: moment(dateStrings[0]).format('YYYY-MM-DDTHH:mm:ss') + 'Z',
            validTo: moment(dateStrings[1]).format('YYYY-MM-DDTHH:mm:ss') + 'Z' })
        )
    }
  };

  const onSave = async () => {
    if (editType === "insert") {
        await axios.post('/coupons', couponInput)
        .then(() => {
            alert(t('common.message.status-change-complete'));
            navigate('/coupons');
        })
        .catch(e => {
            alert(t('common.message.status-change-failed'));
            console.log(e);
        });
    } else {
        await axios.put(`/coupons/${searchParams.get('id')}`, couponInput)
        .then(() => {
            alert(t('common.message.status-change-complete'));
            navigate('/coupons');
        })
        .catch(e => {
            alert(t('common.message.status-change-failed'));
            console.log(e);
        });
    }
  }

  return (
    <>
        <Form className="coupon-form">
            <Form.Item label="Coupon Code" colon={false}>
                <Input
                    name="code"
                    value={couponInput.code}
                    onChange={onCouponInput}
                    placeholder="Please enter coupon code"
                />
            </Form.Item>

            <Form.Item label="Coupon Type" colon={false}>
                <Checkbox checked={couponInput.grantPoints}
                    onClick={() => onTypeCheck('point')}
                >
                    Point Coupon
                </Checkbox>
                <Checkbox checked={couponInput.grantBox}
                    onClick={() => onTypeCheck('box')}
                >
                    Box Coupon
                </Checkbox>
            </Form.Item>

            <Form.Item label="Points" colon={false}>
                <Input type="number"
                    name="points"
                    value={couponInput.points}
                    onChange={onCouponInput}
                    placeholder="Enter points"
                    disabled={!couponInput.grantPoints}
                />
            </Form.Item>

            <Form.Item label="Box Type" colon={false}>
                <Select
                    name="boxCode"
                    value={couponInput.boxCode}
                    onChange={onBoxcodeSelect}
                    placeholder="Select box type"
                    disabled={!couponInput.grantBox}
                >
                { boxList.map((box) => (
                    <Option key={box.id} value={box.boxCode}>{box.boxCode}</Option>
                ))}
                </Select>
            </Form.Item>

            <Form.Item label="Description" colon={false}>
                <Input.TextArea
                    name="description"
                    value={couponInput.description}
                    onChange={onCouponInput}
                    placeholder="Enter coupon description"
                />
            </Form.Item>

            <Form.Item label="Total Issued" colon={false}>
                <Input type="number"
                    name="totalIssued"
                    value={couponInput.totalIssued}
                    onChange={onCouponInput}
                    placeholder="Enter total issued limit"
                />
            </Form.Item>

            {
                editType === 'update' &&
                <Form.Item label="Used Count" colon={false}>
                    <Input type="number"
                        name="usedCount"
                        value={couponInput.usedCount}
                        onChange={onCouponInput}
                        placeholder="Enter used count"
                    />
                </Form.Item>
            }

            <Form.Item label="Validity Range" colon={false}>
                <RangePicker
                    validFrom={couponInput.validFrom}
                    validTo={couponInput.validTo}
                    onRangeChange={onRangeChange} />
            </Form.Item>

            <Form.Item label="Image URL" colon={false}>
                <Input
                    name="image"
                    value={couponInput.image}
                    onChange={onCouponInput}
                    placeholder="Enter image URL"
                />
            </Form.Item>

            {
                editType === 'update' &&
                <Form.Item label="Active Status" colon={false}>
                    <Checkbox checked={couponInput.active}
                        onClick={onActive}
                    >
                        Active
                    </Checkbox>
                </Form.Item>
            }
        </Form>
        <Button type="primary" block onClick={onSave}>Save</Button>
    </>
  );
}
