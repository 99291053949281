import React, { useState, useEffect } from 'react';

import CouponList from '../component/coupon/list/CouponList';
import CouponSearch from '../component/coupon/list/CouponSearch';

import axios from 'axios';
import { useTranslation } from 'react-i18next';

export default function Coupon() {
  const [dataSource, setDataSource] = useState();
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [tablePagination, setTablePagination] = useState({
    pageSize: 20,
    total: 0,
    onChange: async (page, size) => {
      setTablePagination(current => ({
        ...current,
        current: page,
        pageSize: size
      }))
    },
    current: 1,
    position: ['bottomLeft'],
    pageSizeOptions: ['10', '20', '30', '40'],
    showSizeChanger: true
  })
  const [prevSorter, setPrevSorter] = useState({});

  const { t } = useTranslation();

  const getCouponList = async (page, size, sort, keyword) => {
    try {
      const { data } = await axios.get('/coupons/search/any', { 
        params: { 
          page: page-1, 
          size,
          sort: sort ? sort : 'id,desc',
          query: keyword ? keyword : null
        }
      });

      const origin = data.content.map((data) => {
        return { ...data, key: data.id };
      });

      setTablePagination(current => ({
        ...current,
        total: data.totalElements,
        current: page
      }))
      setDataSource(origin);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    let field = prevSorter.field;
    getCouponList(tablePagination.current, tablePagination.pageSize,
      `${field ? field : 'id'},${prevSorter.order === 'ascend' ? 'asc' : 'desc'}`, searchKeyword);
  }, []);

  const handleTableChange = async (pagination, filters, sorter) => {
    let field = sorter.field;
    setTablePagination(current => ({
      ...current,
      current: pagination.current,
      pageSize: pagination.pageSize
    }))

    // 정렬 기준이 변경되었는지 확인
    const isSorterChanged = prevSorter.field !== sorter.field || prevSorter.order !== sorter.order;

    // 정렬 기준이 변경되었다면 페이지네이션을 첫 페이지로 설정, 그렇지 않으면 현재 페이지 사용
    const currentPage = isSorterChanged ? 1 : pagination.current;

    await getCouponList(currentPage, pagination.pageSize,
        `${field ? field : 'id'},${sorter.order === 'ascend' ? 'asc' : 'desc'}`);

    // 현재 정렬 기준을 이전 정렬 기준으로 저장
    setPrevSorter(sorter);
  };

  const onSearchHandler = async (keyword) => {
    let field = prevSorter.field;
    setSearchKeyword(keyword);
    await getCouponList(1, tablePagination.pageSize,
        `${field ? field : 'id'},${prevSorter.order === 'ascend' ? 'asc' : 'desc'}`, keyword);
  }

  return (
    <>
      <CouponSearch
        onSearchHandler={onSearchHandler}
      />
      <CouponList
        getCouponList={getCouponList}
        dataSource={dataSource}
        tablePagination={tablePagination}
        handleTableChange={handleTableChange}
      />
    </>
  );
};